import { Injectable, computed } from '@angular/core';
import { UserConfigurationService } from '../userconfiguration/user-configuration.service';
import { NavigationFilterStateService } from '../navigation-filter-state/navigation-filter-state.service';
import { ScrollService } from '../scroll-service/scroll.service';
import { PlatformService } from '../platform-service/platform.service';

@Injectable({
  providedIn: 'root',
})
export class SideNavigationService {
  public KEY_SIDE_NAVIGATION_OPEN = 'SIDE_NAVIGATION_OPEN';

  private isOpenSideNav: boolean = true;
  isSideNavigationOpen = computed(() => this.navigationFilterStateService.menuBarOpen());
  constructor(private userConfigurationService: UserConfigurationService,
    private navigationFilterStateService: NavigationFilterStateService,
    private scrollService: ScrollService,
    private platformService: PlatformService
  ) {
    this.isOpenSideNav = this.userConfigurationService.getOrDefault(
      this.KEY_SIDE_NAVIGATION_OPEN,
      true,
    );
  }

  isSideNavOpen(): boolean {
    return this.isOpenSideNav;
  }

  toggleSideNav(): void {
    this.updateScrollState();
    this.isOpenSideNav = !this.isOpenSideNav;
    this.userConfigurationService
      .set(this.KEY_SIDE_NAVIGATION_OPEN, this.isOpenSideNav)
      .subscribe();
  }

  openSideNav(): void {
    this.isOpenSideNav = true;
    this.userConfigurationService
      .set(this.KEY_SIDE_NAVIGATION_OPEN, true)
      .subscribe();
  }

  closeSideNav(): void {
    this.isOpenSideNav = false;
    this.userConfigurationService
      .set(this.KEY_SIDE_NAVIGATION_OPEN, false)
      .subscribe();
  }

  checkBehaviorSideNav(): void {
    if (this.isSideNavigationOpen()) {
      this.openSideNav();
    }
    else {
      this.closeSideNav();
    }
  }

  private updateScrollState(): void {
    if (this.platformService.isMobile()) {
      this.isOpenSideNav ? this.scrollService.enableScroll() : this.scrollService.disableScroll();
    }
  }
}
